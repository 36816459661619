/*
fix loading bar 
renaming/commenting code
processed image data
*/

import React, { useEffect, useRef, useState} from 'react';
import OpenSeadragon from 'openseadragon';
import './ImageViewer.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';

const ImageViewer = ({ selectedProcessedImage, onClear }) => {
    const [showPrediction, setShowPrediction] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [jsonData, setJsonData] = useState(null);
    const [isInfoBoxOpen, setIsInfoBoxOpen] = useState(true);
    const viewerRef = useRef(null);
    const viewportPosition = useRef(null);
    const [previousImage, setPreviousImage] = useState(null);

    useEffect(() => {
        setShowPrediction(false);
        if (selectedProcessedImage !== previousImage) {
            viewportPosition.current = null;
            setPreviousImage(selectedProcessedImage);
        }
    }, [selectedProcessedImage]);

    useEffect(() => {
        if (!selectedProcessedImage) return;

        const fetchJsonData = async () => {
            try {
                const { uri, sample_name } = selectedProcessedImage;
                const base_name = sample_name.replace(/\.[^/.]+$/, "");
                const jsonUrl = `${uri}/cell_tables/${base_name}.json`;
                const response = await fetch(jsonUrl);
                if(!response.ok) {
                    throw new Error('Failed to fetch json data');
                }
                const data = await response.json();
                setJsonData(data);
            } catch (error) {
                console.error('Failed to fetch json data: ', error);
            }
        };

        const initializeViewer = () => {
            const { uri, stain_list, sample_name, v_multiplex } = selectedProcessedImage;
            const multiplexString = v_multiplex ? "vMultiplex" : `vIHC-${stain_list}`;
            const base_name = sample_name.split('.').slice(0, -1).join('.');
            const imageUrl = showPrediction
                ? `${uri}/${multiplexString}/${base_name}.dzi`
                : `${uri}/${base_name}_original.dzi`;
        
            if (viewerRef.current) {
                if (selectedProcessedImage === previousImage) {
                    viewportPosition.current = viewerRef.current.viewport.getBounds();
                }
                viewerRef.current.destroy();
                viewerRef.current = null;
            }
        
            const viewerElement = document.getElementById('openseadragon-viewer');
            if (viewerElement) {
                viewerRef.current = OpenSeadragon({
                    id: 'openseadragon-viewer',
                    prefixUrl: 'openseadragon-images/',
                    tileSources: imageUrl,
                    animationTime: 0.5,
                    blendTime: 0.1,
                    minZoomLevel: 0.56,
                    visibilityRatio: 1,
                    zoomPerScroll: 1.2,
                    defaultZoomLevel: 0.56,
                    constrainDuringPan: true,
                    showHomeControl: false,
                    showZoomControl: false,
                    showNavigationControl: false,
                    showSequenceControl: false,
                    showNavigator: true,
                    navigatorPosition: 'TOP_RIGHT',
                    navigatorSizeRatio: 0.2,
                    immediateRender: true,
                    preload: true,
                    maxImageCacheCount: 550,
                    minZoomImageRatio: 0.7,
                    maxZoomPixelRatio: 1.2,
                    timeout: 600000,
                });
        
                viewerRef.current.addHandler('open', () => {
                    setIsLoading(false);
                    if (viewportPosition.current && selectedProcessedImage === previousImage) {
                        viewerRef.current.viewport.fitBounds(viewportPosition.current, true);
                    } else {
                        viewerRef.current.viewport.goHome(true);
                    }
                });

                viewerRef.current.addHandler('resize', () => {
                    viewerRef.current.viewport.goHome(true);
                })
            }
        };

        setIsLoading(true);
        fetchJsonData();
        initializeViewer();
        
    }, [selectedProcessedImage, showPrediction]);

    const handleClear = () => {
        if (viewerRef.current) {
            viewerRef.current.destroy();
            viewerRef.current = null;
        }

        setShowPrediction(false);
        setIsLoading(false);
        setJsonData(null);
        viewportPosition.current = null;
        setPreviousImage(null);

        onClear();
    }

    /*useEffect(() => {
        if(viewerRef.current) {
            viewerRef.current.viewport.goHome(true);
        }
    }, [isInfoBoxOpen]);*/

    const handleToggle = () => setShowPrediction((prev) => !prev);

    const handleDownload = () => {
        if (!selectedProcessedImage) return;

        const { uri, stain_list, sample_name, v_multiplex } = selectedProcessedImage;
        const multiplexString = v_multiplex ? "vMultiplex" : `vIHC-${stain_list}`;
        const base_name = sample_name.replace(/\.[^/.]+$/, "");
        const extension = sample_name.split('.').pop();

        const link = document.createElement('a');
        if (showPrediction) {
            link.href = `${uri}/${multiplexString}/${base_name}.tif`; 
            link.download = `${base_name}-vIHC.tif`;
        } else {
            link.href = `${uri.split('/stained/')[0]}/unstained/${base_name}.${extension}`; 
            link.download = `${base_name}-H&E.${extension}`;
        }
        link.click();
    };

    const toggleInfoBox = (event) => {
        const sidebar = event.currentTarget;
        const sidebarRect = sidebar.getBoundingClientRect();
        const clickX = event.clientX - sidebarRect.left;

        if(clickX <= 40) {
            setIsInfoBoxOpen(prev => !prev);
        }
    }

    return (
        <div className="image-viewer-container">
            {selectedProcessedImage && (
                <button
                    className="clear-button"
                    onClick={handleClear}
                    aria-label="Clear selected image"
                >
                   <CloseIcon/> 
                </button>
            )}
            <div id="openseadragon-viewer" className={`viewer ${isInfoBoxOpen ? 'sidebar-open' : ''}`}></div>
            <div 
                className={`sidebar ${isInfoBoxOpen ? 'open' : ''}`}
                onClick={toggleInfoBox}
            >
                <div className="sidebar-hover-area"></div>
                <div className="toggle-info-box">
                    {isInfoBoxOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </div>
                <div className="info-box-content">
                    <div className="info-box-inner">
                        {selectedProcessedImage ? (
                            <>
                                <label className="prediction-toggle">
                                    <input
                                        type="checkbox"
                                        checked={showPrediction}
                                        onChange={handleToggle}
                                        disabled={isLoading}
                                    />
                                    <span>Display Prediction</span>
                                </label>
                                <div className="image-info-container">
                                    <div className="image-info">
                                        <p><strong>Name:</strong> {selectedProcessedImage.sample_name}</p>
                                        <p><strong>Stains:</strong> {Array.isArray(selectedProcessedImage.stain_list) 
                                            ? selectedProcessedImage.stain_list.join(', ') 
                                            : (selectedProcessedImage.stain_list || 'N/A').replace(/,/g, ', ')}
                                        </p>
                                        <p><strong>Description:</strong> {selectedProcessedImage.description || 'N/A'}</p>
                                        <p><strong>Tags:</strong> {Array.isArray(selectedProcessedImage.tags) && selectedProcessedImage.tags.length 
                                            ? selectedProcessedImage.tags.join(', ') 
                                            : 'N/A'}
                                        </p>
                                    </div>
                                    {showPrediction && jsonData && (
                                        <div className="analysis-container">
                                            <p className="slide-analysis-title">Image Analysis</p>
                                            <div className="stain-info-container">
                                                <p className="total-cells">Total Cells: {jsonData.total_cells}</p>
                                                {jsonData.positive_cells && Object.keys(jsonData.positive_cells).map(stain => (
                                                    <div key={stain} className="stain-info">
                                                        <h4>{stain.toUpperCase()}</h4>
                                                        <p>Positive Cells: {jsonData.positive_cells[stain]}</p>
                                                        <p>Negative Cells: {jsonData.negative_cells?.[stain]}</p>
                                                        <p>Percent Positive: {jsonData.percentage_positive?.[stain]?.toFixed(2)}%</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="download-button-wrapper">
                                    <button
                                        onClick={handleDownload}
                                        disabled={isLoading}
                                        className="download-button"
                                    >
                                        DOWNLOAD IMAGE
                                    </button>
                                </div>
                            </>
                        ) : (
                            <p className="no-image-message">No image selected. Please select an image to view details.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageViewer;